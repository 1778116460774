/*********************************************************************************/
/**********navbar*****************************************************************/
.navbar-container {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  padding: 0 1.6rem;
  z-index: 10;
}
.changecolor {
  background-color: rgba(83, 83, 231, 0.9);
  color: white;
}

.navbar-logo {
  font-size: 2.4rem;
  font-weight: bolder;
}
.menu-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
}
.link {
  text-decoration: none;
  color: #333;
  font-size: 1.6rem;
}
.menu-list a:hover {
  border-bottom: 2px solid #e2e1e1;
  padding-bottom: 0.5rem;
}
.changelink {
  color: white;
}

.mobile-btn-menu {
  display: none;
}
.icon {
  cursor: pointer;
}
.close {
  color: white;
}
/**********responsive***********************************************/
@media screen and (max-width: 830px) {
  .mobile-btn-menu {
    display: block;
    right: 1.6rem;
    cursor: pointer;
    z-index: 1;
  }
  .menu-list {
    color: white;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: -150%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 100vh;
    background-color: rgb(83, 83, 231);
    transition: left 0.6s;
  }
  .active {
    left: 50%;
  }
  .menu-list a {
    font-size: 2.4rem;
    color: white;
  }
}
@media screen and (max-width: 420px) {
  .navbar-container {
    height: 7rem;
  }
}
@media screen and (max-width: 380px) {
  .navbar-container {
    height: 6rem;
  }
}
