* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
p {
  margin: 0;
}
/*************************************************************************/
/**********footer********************************************************/
.footer {
  height: 7rem;
  background-color: rgb(83, 83, 231);
  margin-top: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-text {
  font-size: 1.6rem;
  color: white;
}
/*********************************************************************************/
/*********responsive****************************************************************/
@media screen and (max-width: 285px) {
  .footer-text {
    font-size: 1.3rem;
  }
}
